import React from 'react'
import Loadable from 'react-loadable'
import WrapperComponent from './Wrapper'

function Loading() {
  return <div>Loading...</div>
}

const UsersList = Loadable({
  loader: () => import('../components/Users/UsersList'),
  loading: Loading,
})

const NewUser = Loadable({
  loader: () => import('../components/Users/NewUser'),
  loading: Loading,
})

const PasswordChange = Loadable({
  loader: () => import('../components/Users/PasswordChange'),
  loading: Loading,
})

const ClientsList = Loadable({
  loader: () => import('../components/Clients/ClientsList'),
  loading: Loading,
})

const TotemsList = Loadable({
  loader: () => import('../components/Totems/TotemsList'),
  loading: Loading,
})

const MediaList = Loadable({
  loader: () => import('../components/Media/MediaList'),
  loading: Loading,
})
const CollectionList = Loadable({
  loader: () => import('../components/Collections/CollectionList'),
  loading: Loading,
})
const NewFile = Loadable({
  loader: () => import('../components/Media/NewFile'),
  loading: Loading,
})

const NewCollection = Loadable({
  loader: () => import('../components/Collections/NewCollection'),
  loading: Loading
})

const EditCollection = Loadable({
  loader: () => import('../components/Collections/EditCollection'),
loading: Loading
})

const CatalogsList = Loadable({
  loader: ()=> import('../components/Catalogs/CatalogsList'),
  loading: Loading
})
const NewCatalog = Loadable({
  loader: ()=> import('../components/Catalogs/NewCatalog'),
  loading: Loading
})
const EditCatalog = Loadable({
  loader: ()=> import('../components/Catalogs/EditCatalog'),
  loading: Loading
})
const Statistics = Loadable({
  loader: ()=> import('../components/Statistics/'),
  loading: Loading
})
const routes = [
  { path: '/', exact: true, name: 'Home', component: WrapperComponent },
  { path: '/users/list', exact: true, name: 'Lista użytkowników', component: UsersList },
  { path: '/users/add', exact: true, name: 'Nowy użytkownik', component: NewUser },
  { path: '/users/password-change', exact: true, name: 'Zmiana hasła', component: PasswordChange },
  { path: '/clients/list', exact: true, name: 'Lista klientów', component: ClientsList },
  { path: '/totems/list', exact: true, name: 'Lista totemów', component: TotemsList },
  { path: '/media/list', exact: true, name: 'Lista mediów', component: MediaList },
  { path: '/media/add', exact: true, name: 'Nowy Plik', component: NewFile },
  { path: '/collections/list', exact: true, name: 'Lista Kolekcji', component: CollectionList},
  { path: '/collections/add', exact: true, name: 'Nowa Kolekcja', component: NewCollection},
  { path: '/collections/edit/:id', exact: true, name: 'Edytuj Kolekcje', component: EditCollection},
  { path: '/catalogs/list', exact: true, name: 'Lista Katalogów', component: CatalogsList},
  { path: '/catalogs/add', exact: true, name: 'Nowy Katalog', component: NewCatalog},
  { path: '/catalogs/edit/:id', exact: true, name: 'Edytuj Katalog', component: EditCatalog}, 
  { path: '/statistics', exact: true, name: 'Statystyki', component: Statistics}, 
]

export default routes
