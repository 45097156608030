import { init } from '@rematch/core'
import { currentUser } from './models/user'
import { router } from './models/router'
import createReactRouterPlugin from './models/router/plugin'
const store = init({
  models: {
    currentUser,
    router,
  },
  plugins: [createReactRouterPlugin],
})

export default store
