import { routerMiddleware } from 'react-router-redux'
import router from './index'
import customHistory from '../../history'

export default function createReactRouterPlugin() {
  return {
    config: {
      models: {
        router: router,
      },
      redux: {
        middlewares: [routerMiddleware(customHistory)],
      },
    },
    onStoreCreated() {
      return {
        customHistory,
      }
    },
  }
}
