import customHistory from '../redux/history'
import store from '../redux/index'

import wretch from 'wretch'
import applyToken from '../redux/middlewares/applyToken'
import { ServerErrorAlert } from './alert';

export const withAuthFormData = wretch()
  .middlewares([applyToken()])
  .headers({})
  .catcher(401, err => {
    store.dispatch.currentUser.logOut();
    customHistory.push('/')
    ServerErrorAlert('Sesja Wygasła', 'Sesja wygasła zaloguj się ponownie')
  })

export const withAuth = wretch()
  .middlewares([applyToken()])
  .content('application/json')
  .catcher(401, err => {
    store.dispatch.currentUser.logOut();
    customHistory.push('/')
    ServerErrorAlert('Sesja Wygasła', 'Sesja wygasła zaloguj się ponownie')
  })

export const callApi = wretch().content('application/json')

export const apiCatch = error => {
  if (error.message && error.message.indexOf('fetch') >= 0)
      // api connection error
      callApi
        .url('/')
        .get()
        .json()
        .then(result => {
            if (result)
                location.reload()
        })
        .catch(() => {
          ServerErrorAlert('Błąd serwera', 'Wystąpił błąd połączenia z serwerem. Spróbuj ponownie później.')
        })
    else
      // others errors
      throw error;
}
