import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
} from 'reactstrap'

import PropTypes from 'prop-types'

import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from '@coreui/react'

import logo from '../../../public/images/logo.svg'
import customHistory from '../../redux/history'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

class DefaultHeader extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand full={{ src: logo, width: 89, height: 25, alt: 'Tubadzin Logo' }} />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <span>{this.props.currentUser.email}</span>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem
                onClick={() => {
                  customHistory.push('/users/password-change')
                }}>
                <i className="fa fa-shield" /> Zmień hasło
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.props.logOut()
                  customHistory.push('/login')
                }}>
                <i className="fa fa-lock" /> Wyloguj się
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    )
  }
}

DefaultHeader.propTypes = propTypes
DefaultHeader.defaultProps = defaultProps

export default connect(
  state => ({ currentUser: state.currentUser }),
  dispatch => ({
    logOut: () => dispatch.currentUser.logOut(),
  })
)(DefaultHeader)
