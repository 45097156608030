import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import { ThemeProvider } from 'styled-components'
import 'bootstrap/scss/bootstrap.scss'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import 'react-dates/initialize';
import './scss/style.scss'
import customHistory from './redux/history'
import store from './redux'
import main from './themes/main'
import Wrapper from './screens/Wrapper'
import moment from 'moment'

moment.locale('pl')

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={main}>
      <ConnectedRouter history={customHistory}>
        <Switch>
          <Route path="/" component={Wrapper} />
        </Switch>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('app')
)
