import produce from 'immer'
import { callApi, withAuth, apiCatch } from '../../../utils/api'
import {
  getCurrentUserId,
  getCurrentUserEmail,
  getCurrentUserRole,
  getCurrentUserToken,
  setCurrentUserId,
  setCurrentUserEmail,
  setCurrentUserRole,
  setCurrentUserToken,
  removeUserFromLocalStorage,
} from '../../../utils/auth'

import { API } from '../../../constants'

export const currentUser = {
  state: {
    id: getCurrentUserId(),
    email: getCurrentUserEmail(),
    role: getCurrentUserRole(),
    token: getCurrentUserToken(),
  },
  reducers: {
    setCurrentUser(state, payload) {
      return produce(state, draft => {
        draft.id = payload.userId
        draft.email = payload.email
        draft.role = payload.role
        draft.token = payload.token
      })
    },

    clearUser(state) {
      return produce(state, draft => {
        draft.id = null
        draft.email = null
        draft.role = null
        draft.token = null
      })
    },
  },
  effects: {
    async logIn({ email, password }) {
      await callApi
        .url(`${API}/token`)
        .post({ email, password })
        .json(res => {
          //setToken(res.token)

          //local storage setup
          setCurrentUserId(res.userId)
          setCurrentUserEmail(res.email)
          setCurrentUserRole(res.role)
          setCurrentUserToken(res.token)

          //redux setup
          this.setCurrentUser(res)
        })
        .catch(apiCatch)
      // res && setToken(res.token)
    },

    logOut() {
      removeUserFromLocalStorage()
      this.clearUser()
    },

    async resetPassword({ oldPassword, newPassword }) {
      await withAuth
        .url(`${API}/users/password-change`)
        .post({ oldPassword, newPassword })
        .json(res => {
          this.setCurrentUser(res)
        })
        .catch(apiCatch)
        .catch(err => {
          throw new Error(err)
        })
    },

    async createNewUser({ email, password }) {
      await withAuth
        .url(`${API}/users`)
        .post({ email, password })
        .json(res => {
          this.setCurrentUser(res)
        })
        .catch(apiCatch)
        .catch(err => {
          throw new Error(err)
        })
    },
  },
}
