import {
  USER_TOKEN_KEY,
  CURRENT_USER_ID,
  CURRENT_USER_EMAIL,
  CURRENT_USER_ROLE,
  CURRENT_USER_TOKEN,
} from '../constants'

export const setToken = token => {
  localStorage.setItem(USER_TOKEN_KEY, token)
}

export const removeToken = () => {
  localStorage.removeItem(USER_TOKEN_KEY)
}

export const getToken = () => {
  return localStorage.getItem(USER_TOKEN_KEY)
}

export const setCurrentUserId = userId => {
  localStorage.setItem(CURRENT_USER_ID, userId)
}
export const setCurrentUserEmail = email => {
  localStorage.setItem(CURRENT_USER_EMAIL, email)
}
export const setCurrentUserRole = role => {
  localStorage.setItem(CURRENT_USER_ROLE, role)
}
export const setCurrentUserToken = token => {
  localStorage.setItem(CURRENT_USER_TOKEN, token)
}

export const getCurrentUserId = () => {
  return localStorage.getItem(CURRENT_USER_ID)
}
export const getCurrentUserEmail = () => {
  return localStorage.getItem(CURRENT_USER_EMAIL)
}
export const getCurrentUserRole = () => {
  return localStorage.getItem(CURRENT_USER_ROLE)
}
export const getCurrentUserToken = () => {
  return localStorage.getItem(CURRENT_USER_TOKEN)
}

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem(CURRENT_USER_ID)
  localStorage.removeItem(CURRENT_USER_EMAIL)
  localStorage.removeItem(CURRENT_USER_ROLE)
  localStorage.removeItem(CURRENT_USER_TOKEN)
}
