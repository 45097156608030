export default {
  items: [
    {
      name: 'Użytkownicy',
      url: '/users/list',
      icon: 'icon-user',
      children: [
        {
          name: 'Lista użytkowników',
          url: '/users/list',
          icon: 'icon-user',
        },
        {
          name: 'Dodaj nowego',
          url: '/users/add',
          icon: 'icon-pencil',
        },
      ],
    },
    {
      name: 'Klienci',
      url: '/clients/list',
      icon: 'icon-basket-loaded',
    },
    {
      name: 'Totemy',
      url: '/totems/list',
      icon: 'icon-screen-desktop',
    },
    {
      name: 'Media',
      url: '/media/list',
      icon: 'icon-folder',
      children: [
        {
          name: 'Lista mediów',
          url: '/media/list',
          icon: 'icon-folder',
        },
        {
          name: 'Dodaj nowy plik',
          url: '/media/add',
          icon: 'icon-cloud-upload',
        },
      ],
    },
    {
      name: 'Kolekcje',
      url: '/collections/list',
      icon: 'icon-grid',
      children: [
        {
          name: 'Lista kolekcji',
          url: '/collections/list',
          icon: 'icon-grid',
        },
        {
          name: 'Nowa Kolekcja',
          url: '/collections/add',
          icon: 'icon-pencil',
        },
      ],
    },
    {
      name: 'Katalogi',
      url: 'catalogs/list',
      icon: 'icon-book-open',
      children: [
        {
          name: 'Lista katalogów',
          url: '/catalogs/list',
          icon: 'icon-book-open',
        },
        {
          name: 'Nowy Katalog',
          url: '/catalogs/add',
          icon: 'icon-pencil',
        },
      ],
    },

    {
      name: 'Statystyki',
      url: '/statistics',
      icon: 'icon-graph',
    },
  ],
}
