import React, { PureComponent, Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import Loading from '../components/Common/Loading'
import { Wrapper } from '../themes/global'

import '@coreui/icons/css/coreui-icons.min.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'font-awesome/css/font-awesome.min.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'react-table/react-table.css'
import '../scss/style.css'

//////////////////////////////////////////////////////////

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react'

// sidebar nav config
import navigation from './_nav'

// routes config
import routes from './routes'

import DefaultFooter from '../components/Layout/DefaultFooter'
import DefaultHeader from '../components/Layout/DefaultHeader'

//////////////////////////////////////////////////////////

const LoginPage = Loadable({
  loader: () => import('./LoginPage'),
  loading: Loading,
})

const ForgottenPasswordPage = Loadable({
  loader: () => import('./ForgottenPasswordPage'),
  loading: Loading,
})

const ResetPasswordPage = Loadable({
  loader: () => import('./ResetPasswordPage'),
  loading: Loading,
})

class WrapperComponent extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route path="/forgottenPassword" exact component={ForgottenPasswordPage} />
          <Route path="/resetPassword" exact component={ResetPasswordPage} />
          <Route path="/">
            <Fragment>
              {!this.props.currentUser.email && <Redirect to="/login" />}

              <div className="app">
                <AppHeader fixed>
                  <DefaultHeader />
                </AppHeader>

                <div className="app-body">
                  <AppSidebar fixed display="lg">
                    <AppSidebarHeader />
                    <AppSidebarForm />
                    <AppSidebarNav navConfig={navigation} {...this.props} />
                    <AppSidebarFooter />
                    <AppSidebarMinimizer />
                  </AppSidebar>

                  <main className="main">
                    <AppBreadcrumb appRoutes={routes} />
                    <Container fluid>
                      <Switch>
                        {routes.map((route, idx) => {
                          return route.component ? (
                            <Route
                              key={idx}
                              path={route.path}
                              exact={route.exact}
                              name={route.name}
                              render={props => <route.component {...props} />}
                            />
                          ) : null
                        })}

                        {this.props.currentUser.email && <Redirect to="/" />}
                      </Switch>
                    </Container>
                  </main>
                </div>

                <AppFooter>
                  <DefaultFooter />
                </AppFooter>
              </div>
            </Fragment>
          </Route>
        </Switch>
      </Wrapper>
    )
  }
}

const mapState = state => ({
  currentUser: state.currentUser,
})

export default connect(mapState)(WrapperComponent)
